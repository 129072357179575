import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fileName"]

  uploadFile(event) {
    const fileInput = event.target
    const fileNameTarget = this.fileNameTarget

    if (fileInput.files.length > 0) {
      fileNameTarget.textContent = fileInput.files[0].name
    } else {
      fileNameTarget.textContent = "Nenhum arquivo selecionado"
    }

    this.element.requestSubmit()
  }
}
