import { Controller } from "@hotwired/stimulus";
import IMask from "imask";

export default class extends Controller {
  static values = {
    mask: String,
  };

  connect() {
    this.applyMask();
    this.registerFormSubmitListener();
  }

  applyMask() {
    if (this.maskValue) {
      this.maskInstance = IMask(this.element, { mask: this.maskValue });
    }
  }

  registerFormSubmitListener() {
    const form = this.element.closest("form");
    if (form) {
      form.addEventListener("submit", this.removeMask.bind(this));
    }
  }

  removeMask(event) {
    if (this.maskInstance) {
      this.element.value = this.maskInstance.unmaskedValue;
    }
  }
}
