import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["cnpj", "legalName", "tradeName"];

  lookupCompany() {
    const cnpj = this.cnpjTarget.value;
    if (this.isValidCNPJ(cnpj)) {
      this.fetchCompany(cnpj)
        .then(data => this.handleCompanyData(data))
        .catch(error => this.handleFetchError(error));
    } else {
      console.log("CNPJ inválido. Por favor, verifique.");
    }
  }

  async fetchCompany(cnpj) {
    try {
      const response = await fetch(`/cnpj_lookup/fetch_company?cnpj=${cnpj}`);

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Erro ao buscar informações do CNPJ.');
      }
    } catch (error) {
      throw new Error('Erro na comunicação com o servidor.');
    }
  }

  handleCompanyData(data) {
    if (data.error) {
      alert("CNPJ não encontrado ou erro na requisição.");
    } else {
      if (this.hasLegalNameTarget) {
        this.legalNameTarget.value = data.nome || "";
      }
      if (this.hasTradeNameTarget) {
        this.tradeNameTarget.value = data.fantasia || "";
      }
    }
  }

  isValidCNPJ(cnpj) {
    const cleanedCNPJ = cnpj.replace(/\D/g, '');
    const isValid = cleanedCNPJ.length === 14;
    return isValid;
  }

  handleFetchError(error) {
    alert(error.message || "Erro ao buscar informações do CNPJ.");
  }
}
