import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "results", "query"];

  connect() {
    console.log("Search controller connected");
    this.debounceTimeout = null;
  }

  search(event) {
    clearTimeout(this.debounceTimeout);

    this.debounceTimeout = setTimeout(() => {
      const url = new URL(this.formTarget.action);
      url.searchParams.set("query", this.queryTarget.value);

      fetch(url, {
        method: "GET",
        headers: { "Accept": "text/vnd.turbo-stream.html" },
      })
        .then(response => response.text())
        .then(html => {
          this.resultsTarget.innerHTML = html;
        });
    }, 300);
  }
}
